
<template>
    <div class="Pool">
        <div class="poolContentBox">
            <div class="connectBoxW allPools" v-if="haveYuorLiquidity">
                <h2 class="title">Your Liquidity</h2>
                <ul class="titleUl">
                    <li>NAME</li>
                    <li>Liquidity</li>
                    <li class="allYourLiquidity">
                        Your Liquidity (without farm)
                    </li>
                    <li>APR</li>
                    <li></li>
                </ul>
                <ul class="poolList">
                    <li class="item" v-for="(item,index) in poolsInfo" :key="index">
                        <div v-if="item.yourliquidity != 0" :class="item.show ? 'activeItem' : ''">
                            <div class="pcinfo">
                                <p class="name">
                                    <span>
                                        <img :src="item.token1.logoURI" alt="">
                                        <img :src="item.token0.logoURI" alt="">
                                    </span>
                                    {{item.token0.symbol}}-{{item.token1.symbol}}
                                </p>
                                <p class="liquidity">$ {{item.liquidity.toFixed(0)}}</p>
                                <p class="yourLiquidity">$ {{ item.yourliquidity == 0 ? '0.000000' : item.yourliquidity.toFixed(8) }}</p>
                                <p class="apr">{{item.apr.toFixed(2)}}%  <i v-if="item.isFarm" class="el-icon-arrow-right"></i> <span v-if="item.isFarm">{{item.maxAPR}}%(Farm)</span></p>
                                <p class="btn">
                                    <span v-if="item.isFarm">
                                        <router-link v-if="item.V == 2" tag="a" to="/Farm">
                                            Farm
                                        </router-link>
                                        <a v-else href="https://app-v1.spacefi.io/#/farm">
                                            Farm
                                        </a>
                                    </span>
                                    <span @click="goSwap">Manage Liquidity</span>
                                </p>
                            </div>
                            <div class="iphoneinfo" @click="clickYourItem(index)">
                                <p class="name">
                                    <span class="names">
                                        <span class="lpLogo">
                                            <img :src="item.token1.logoURI" alt="">
                                            <img :src="item.token0.logoURI" alt="">
                                        </span>
                                        {{item.token0.symbol}}-{{item.token1.symbol}}
                                    </span>
                                    
                                </p>
                                <p class="liquidity">
                                    <span>Liquidity</span>
                                    <span>$ {{item.liquidity}}</span>
                                </p>
                                <p class="apr">
                                    <span>APR</span>
                                    <span>{{item.apr}}%</span>
                                </p>
                            </div>
                            <div v-if="item.show" class="btns">
                                <a href="">Add Liquidity</a>
                                <a v-if="item.yourliquidity != 0" href="">Remove Liquidity</a>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="connectBoxW allPools">
                <h2 class="title">All Pools</h2>
                <ul class="titleUl">
                    <li>NAME</li>
                    <li>Liquidity</li>
                    <li class="allYourLiquidity">
                        Your Liquidity (without farm)
                        <!-- <i class="el-icon-question"><span>Your Liquidity (without farm)</span></i> -->
                        
                    </li>
                    <li>APR</li>
                    <li></li>
                </ul>
                <ul class="poolList">
                    <li class="item" v-for="(item,index) in allpoolPagelist" :key="index">
                        <div :class="item.show ? 'activeItem' : ''">
                            <div class="pcinfo">
                                <p class="name">
                                    <span>
                                        <img :src="item.token1.logoURI" alt="">
                                        <img :src="item.token0.logoURI" alt="">
                                    </span>
                                    {{item.token0.symbol}}-{{item.token1.symbol}}
                                </p>
                                <p class="liquidity">$ {{item.liquidity.toFixed(0)}}</p>
                                <p class="yourLiquidity">$ {{ item.yourliquidity == 0 ? '0.000000' : item.yourliquidity.toFixed(8) }}</p>
                                <p class="apr">{{item.apr.toFixed(2)}}%  <i v-if="item.isFarm" class="el-icon-arrow-right"></i> <span v-if="item.isFarm">{{item.maxAPR}}%(Farm)</span></p>
                                <p class="btn">
                                    <span v-if="item.isFarm">
                                        <router-link v-if="item.V == 2" tag="a" to="/Farm">
                                            Farm
                                        </router-link>
                                        <a v-else href="https://app-v1.spacefi.io/#/farm">
                                            Farm
                                        </a>
                                    </span>
                                    <span @click="goSwap">Manage Liquidity</span>
                                </p>
                            </div>
                            <div class="iphoneinfo" @click="clickYourItem(index)">
                                <p class="name">
                                    <span class="names">
                                        <span class="lpLogo">
                                            <img :src="item.token1.logoURI" alt="">
                                            <img :src="item.token0.logoURI" alt="">
                                        </span>
                                        {{item.token0.symbol}}-{{item.token1.symbol}}
                                    </span>
                                    
                                </p>
                                <p class="liquidity">
                                    <span>Liquidity</span>
                                    <span>$ {{item.liquidity}}</span>
                                </p>
                                <p class="apr">
                                    <span>APR</span>
                                    <span>{{item.apr}}%</span>
                                </p>
                                <!-- <p class="show"><i :class=" item.show ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i></p> -->
                            </div>
                            <div v-if="item.show" class="btns">
                                <a href="">Add Liquidity</a>
                                <a v-if="item.yourliquidity != 0" href="">Remove Liquidity</a>
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="pageBox" v-if="zksyncpoollist.length > 10">
                    <div class="block">
                        <el-pagination
                            layout="prev, pager, next"
                            :page-size="pagesize"
                            :total="zksyncpoollist.length"
                            @current-change="changePage">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
        <v-Footer></v-Footer>
    </div>
</template>

<script>
import { getAllPairsLength,getAllPairs,getBalanceOf,gettotalSupply,getPairToken0,getPairToken1,getTokenSymbol } from '@/utils/space.js'
export default {
    name: "Pool",
    data() {
        return {
            userAddress:'',haveYuorLiquidity:false,
            pagesize:10,
            poolsInfo:[],
            allpoolPagelist:[
                
            ]
        }
    },
    async mounted(){
        this.userAddress = localStorage.getItem('userAddress')
        this.tokenSymbol = localStorage.getItem('tokenSymbol')
        this.xtokenSymbol = localStorage.getItem('xtokenSymbol')
        this.zksynctokenlist = window.zksynctokenlist
        this.zksyncpoollist = window.zksyncpoollist
        this.zksyncpoollist.forEach((item,index) => {

            var obj = {}
            obj.token0 = {}
            obj.token1 = {}
            obj.contract = item.address
            obj.isFarm = item.isFarm
            obj.maxAPR = item.maxAPR
            obj.V = item.V
            obj.index = index-0
            getPairToken0(item.address).then(token0 => {
                obj.token0.contract = token0
                getTokenSymbol(token0).then(res => {
                    obj.token0.symbol = res
                })
                this.zksynctokenlist.forEach((item,index) => {
                    if(item.address.toUpperCase() == token0.toUpperCase()){
                        obj.token0.logoURI = item.logoURI
                    }
                })
                getPairToken1(item.address).then(token1 => {
                    obj.token1.contract = token1
                    getTokenSymbol(token1).then(res => {
                        obj.token1.symbol = res
                    })
                    this.zksynctokenlist.forEach((item,index) => {
                        if(item.address.toUpperCase() == token1.toUpperCase()){
                            obj.token1.logoURI = item.logoURI
                        }
                    })
                    this.$axios.get('https://api.dexscreener.com/latest/dex/pairs/zksync/'+item.address).then(info => {
                        if(info){
                            var liquidity = info.pairs[0].liquidity.usd
                            var volume = info.pairs[0].volume.h24
                            obj.apr = volume*0.3/100*365/liquidity*100*0.5
                            obj.liquidity = info.pairs[0].liquidity.usd 
                        } else {
                            obj.apr = 0
                            obj.liquidity = 0
                        }
                        if(this.userAddress.indexOf(0) != -1){
                            getBalanceOf(item.address,this.userAddress).then(res => {
                                if (res != 0) {
                                    this.haveYuorLiquidity = true
                                }
                                gettotalSupply(item.address).then(total => {
                                    obj.yourliquidity = (res/Math.pow(10,18))*(info.pairs[0].liquidity.usd/(total/1e18))
                                    this.poolsInfo.push(obj)
                                    this.poolsInfo.sort((a,b) => {
                                        if (a.liquidity > b.liquidity) { return -1;
                                        } else if (a.liquidity < b.liquidity) { return 1;
                                        } else { return 0; }
                                    })
                                    this.allpoolPagelist = this.poolsInfo.slice(0,10)
                                })
                            })
                        } else {
                            obj.yourliquidity = 0
                            this.poolsInfo.push(obj)
                            this.poolsInfo.sort((a,b) => {
                                if (a.liquidity > b.liquidity) { return -1;
                                } else if (a.liquidity < b.liquidity) { return 1;
                                } else { return 0; }
                            })
                            this.allpoolPagelist = this.poolsInfo.slice(0,10)
                        }
                    
                    })
                
                })
            })
        })
        
        
    },
    methods: {
        changePage(page){
            this.allpoolPagelist = this.poolsInfo.slice(page*10-10,page*10)
        },
        goFarm (v) {
            window.open( v == 2 ? "https://spacefi.io/farm" : "https://app-v1.spacefi.io/#/farm","_self")
        },
        goSwap () {
            window.open("https://swap-testnet.beraspace.io/#/swap","_self")
        }
    }
}
</script>